import React, { useEffect, useState } from 'react';
import GlossaryToolbar from './glossaryToolbar';
import GlossaryTerm from './glossaryTerm';
import GlossaryTermEdit from './glossaryTermEdit';
import '../../App.css';
import {
    Button,
    CircularProgress,
} from '@material-ui/core';
import {
    DesktopWindows as ProdIcon,
    DesktopAccessDisabled as NoProdIcon,
} from '@material-ui/icons';
import {
    getGlossary, // send env level ("staging"/"prod") & status ("active"/"archived") and get terms
    getNavigation, // send 'glossary', get category list from source environment (staging or dev) nav table
    createTerm, // create term in lower env only
    updateTerm, // update term in lower env only
    archiveTerm, // set lower env isArchived=true
    restoreTerm, // set lower env isArchived=false
    promoteTerm, // create new upper env term from lower env, mark existing upper env terms isArchived=true
    unpromoteTerm, // set upper env isArchived=true
    requestPresignedUrlForFile,
    uploadFileWithPresignedUrl,
} from '../../apiService';
import {
    gets3Bucket, // gets S3 Bucket, pass in 'isTemplate' boolean
} from '../../utils';
import config from '../../config';


const ManageGlossaryComponent = () => {
    const [upperTerms, setUpperTerms] = useState([]);
    const [lowerTerms, setLowerTerms] = useState([]);
    const [archivedTerms, setArchivedTerms] = useState([]);
    const [createMode, setCreateMode] = useState(false);
    const [hidePromoted, setHidePromoted] = useState(false);
    const [menuCategories, setMenuCategories] = useState([]); // For creating new terms - only the categories from the staging menu
    const [categoriesInUse, setCategoriesInUse] = useState([]); // For filtering terms - all the categories being used in upper, lower, or archive
    const [filter, setFilter] = useState({
        category: '',
        text: '',
        tabView: 'lower',
    });
    const [filteredTerms, setFilteredTerms] = useState([]);

    const [loadingProd, setLoadingProd] = useState(false);
    const [loadingStaging, setLoadingStaging] = useState(false);
    const [loadingArchive, setLoadingArchive] = useState(false);

    // On page load, get glossary terms & categories
    useEffect(() => {
        refreshTerms();
        loadGlossaryCategories();
    }, []);

    // When upper or lower env terms are updated, determine which lower env terms are already promoted & refresh filtered list
    useEffect(() => {
        compareLowerAndUpperTerms();
    }, [lowerTerms, upperTerms]);

    // When filter, or view mode is updated, refresh which terms will be shown
    useEffect(() => {
        updateFilteredTerms();
    }, [archivedTerms, filter, hidePromoted]);

    // When any of the term sets are updated, recreate the categoriesInUse array (for filtering) to reflect actual term category data
    useEffect(() => {
        updateCategories();
    }, [lowerTerms, upperTerms, archivedTerms]);

    function loadActiveProdTerms() {
        setLoadingProd(true);
        getGlossary('prod', 'active')
            .then(data => {
                const terms = data.Items;
                terms.forEach(item => item.status = 'upper');
                setUpperTerms(terms);
                setLoadingProd(false);
            })
            .catch(error => {
                console.log(`ERROR: failed to load upper environment active glossary term data. ${JSON.stringify(error.statusText)}`);
                console.error(error);
            });
    }

    function loadActiveStagingTerms() {
        setLoadingStaging(true);
        getGlossary('staging', 'active')
            .then(data => {
                const terms = data.Items;
                terms.forEach(item => item.status = 'lower');
                setLowerTerms(terms);
                setLoadingStaging(false);
            })
            .catch(error => {
                console.log(`ERROR: failed to load lower environment active glossary term data. ${JSON.stringify(error.statusText)}`);
                console.error(error);
            });

    }

    function loadArchivedStagingTerms() {
        setLoadingArchive(true);
        getGlossary('staging', 'archived')
            .then(data => {
                const terms = data.Items;
                terms.forEach(item => item.status = 'archive');
                setArchivedTerms(terms);
                setLoadingArchive(false);
            })
            .catch(error => {
                console.log(`ERROR: failed to load lower environment archive glossary term data. ${JSON.stringify(error.statusText)}`);
                console.error(error);
            });
    }

    function refreshTerms() {
        loadActiveStagingTerms();
        loadArchivedStagingTerms();
        loadActiveProdTerms();
        setTimeout(compareLowerAndUpperTerms, 3000);
    }

    function loadGlossaryCategories() {
        getNavigation('glossary')
            .then((data) => {
                const tiles = data.items.tileSections.Glossary.tiles;
                const categories = tiles.map(item => item.title).filter(item => item !== "All Terms (A-Z)");
                setMenuCategories(categories.sort((a, b) => a.localeCompare(b)));
            })
            .catch((error) => {
                console.log(`ERROR: failed to load category data. ${JSON.stringify(error.statusText)}`);
                console.error(error);
            });
    }

    function updateFilteredTerms() {
        let termsToShow = [];
        // Add all terms from appropriate arrays
        switch (filter.tabView) {
            case 'upper': termsToShow = [...upperTerms]; break;
            case 'lower': termsToShow = [...lowerTerms]; break;
            case 'archive': termsToShow = [...archivedTerms]; break;
            default: break;
        }
        // Alphabetize terms
        termsToShow.sort((a, b) => (a.term > b.term) ? 1 : -1)
        // Filter by category
        if (filter.category) {
            termsToShow = termsToShow.filter(item => item.category === filter.category);
        }
        // Filter by text
        if (filter.text) {
            termsToShow = termsToShow.filter(item => filterCheck(item, filter.text.toLowerCase()));
        }
        // Hide promoted if selected
        if (hidePromoted) {
            termsToShow = termsToShow.filter(item => !item.isPromoted);
        }
        setFilteredTerms(termsToShow);
    }

    function filterCheck(item, filterText) {
        // Include if found in term name, category, description, disclaimer, or notes
        if (item.term?.toLowerCase().includes(filterText)) return true;
        if (item.category?.toLowerCase().includes(filterText)) return true;
        if (item.description?.toLowerCase().includes(filterText)) return true;
        if (item.disclaimer?.toLowerCase().includes(filterText)) return true;
        if (item.notes?.toLowerCase().includes(filterText)) return true;
        // Include if found in related content link name
        if (item.relatedContent) {
            if (item.relatedContent[0] && item.relatedContent[0].name?.toLowerCase().includes(filterText)) return true;
            if (item.relatedContent[1] && item.relatedContent[1].name?.toLowerCase().includes(filterText)) return true;
            if (item.relatedContent[2] && item.relatedContent[2].name?.toLowerCase().includes(filterText)) return true;
        }
        return false;
    }

    function compareLowerAndUpperTerms() {
        function termsMatch(termA, termB) {
            if (!stringsMatch(termA.term, termB.term)) return false;
            if (!stringsMatch(termA.category, termB.category)) return false;
            if (!stringsMatch(termA.description, termB.description)) return false;
            if (!stringsMatch(termA.disclaimer, termB.disclaimer)) return false;
            if (!stringsMatch(termA.featureAvailability, termB.featureAvailability)) return false;
            if (!relatedContentMatch(termA.relatedContent, termB.relatedContent)) return false;
            if (JSON.stringify(termA.images?.filter(String)) != JSON.stringify(termB.images?.filter(String))) return false;
            return true;
        }

        // returns true if strings match, including null === '' as matching
        function stringsMatch(stringA, stringB) {
            if (stringA === stringB) return true;
            if (!stringA && !stringB) return true;
            return false;
        }

        // returns true if related content matches, ignoring objects without name or url strings
        function relatedContentMatch(arrayA, arrayB) {
            if (!arrayA) arrayA = [{ name: "", url: "" }, { name: "", url: "" }, { name: "", url: "" }];
            if (!arrayB) arrayB = [{ name: "", url: "" }, { name: "", url: "" }, { name: "", url: "" }];
            arrayA.filter(item => (item.name != "" && item.url != ""));
            arrayB.filter(item => (item.name != "" && item.url != ""));
            if (JSON.stringify(arrayA) === JSON.stringify(arrayB)) return true;
            return false;
        }

        // If there are upper & lower env term arrays to compare
        if (upperTerms.length > 0 && lowerTerms.length > 0) {
            const termsArray = lowerTerms;
            for (let i = 0; i < termsArray.length; i++) {
                // Look for a term in upper env with a stagingId that matches this term
                const prodTerm = upperTerms.find(item => item.stagingId == termsArray[i].id);
                if (prodTerm) {
                    if (termsMatch(prodTerm, termsArray[i])) {
                        // If everything matches the promoted version
                        termsArray[i].isPromoted = true;
                    } else {
                        // If anything doesn't match the promoted version
                        termsArray[i].isPromoted = false;
                    }
                } else {
                    // If there isn't a term in upper env with this stagingId
                    termsArray[i].isPromoted = false;
                }
            }
            setLowerTerms(termsArray);
        }
        updateFilteredTerms();
    }

    function updateCategories() {
        const categories = [];

        archivedTerms?.forEach(term => {
            if (!categories.includes(term.category)) {
                categories.push(term.category);
            }
        })

        lowerTerms?.forEach(term => {
            if (!categories.includes(term.category)) {
                categories.push(term.category);
            }
        })

        upperTerms?.forEach(term => {
            if (!categories.includes(term.category)) {
                categories.push(term.category);
            }
        })

        setCategoriesInUse(categories.sort((a, b) => a.localeCompare(b)));
    }

    async function createNewTerm(body, callback) {
        createTerm(body, () => {
            setCreateMode(false);
            setLowerTerms([ { status: 'lower', isPromoted: false, ...body }, ...lowerTerms ]);
            callback();
            refreshTerms();
        });
    }

    async function updateTermInStaging(id, body, callback) {
        updateTerm(id, body, () => {
            callback();
            refreshTerms();
        });
    }

    async function publishToProd(body, callback) {
        promoteTerm(body, () => {
            callback();
            refreshTerms();
        });
    }

    async function removeFromProd(id, callback) {
        unpromoteTerm(id, () => {
            callback();
            refreshTerms();
        });
    }

    async function removeFromStaging(id, callback) {
        archiveTerm(id, () => {
            callback();
            refreshTerms();
        });
    }

    async function restoreTermToStaging(id, callback) {
        restoreTerm(id, () => {
            refreshTerms();
            callback();
        });
    }

    function uploadImage(file, id) {
        return new Promise(
            (resolve, reject) => {
                let requestBody = {
                    key: 'static/images/glossary/' + id + "/" + file.name,
                    bucket: gets3Bucket(false)
                }
                requestPresignedUrlForFile(requestBody)
                    .then(function (response) {
                        const signedUrl = response.data;
                        uploadFileWithPresignedUrl(signedUrl, file.name, file)
                            .then(function (response) {
                                return resolve(response);
                            })
                    })
                    .catch(function (err) {
                        console.error("Error uploading " + file.name + " " + err);
                        reject(err);
                    });
                return resolve();
            });
    }

    function getFilterText() {
        if (filter.text === '' && filter.category === '') return ':';
        if (filter.text !== '' && filter.category !== '') return ` matching "${filter.text.toLowerCase()}" & "${filter.category.toLowerCase()}":`;
        return ` matching "${filter.text.toLowerCase()}${filter.category.toLowerCase()}":`;
    }

    function getResultsMessage() {
        let environment;
        switch (filter.tabView) {
            case 'upper': environment = config.environments.destinationEnvironment; break;
            case 'lower': environment = config.environments.sourceEnvironment; break;
            case 'archive': environment = 'archive'; break;
        }
        if (loadingProd && filter.tabView === 'upper' ||
            loadingStaging && filter.tabView === 'lower' ||
            loadingArchive && filter.tabView === 'archive') {
            return <>Loading glossary terms... <CircularProgress size="14px" color="inherit" /></>;
        }
        return `Found ${filteredTerms.length}${hidePromoted && filter.tabView === 'lower' ? ' unpromoted' : ''} term${filteredTerms.length !== 1 ? 's' : ''} in ${environment}${getFilterText()}`
    }

    const blankTerm = {
        term: "",
        category: "",
        status: "create",
        description: "",
        notes: "",
        images: [],
        relatedContent: [{ name: "", url: "" }, { name: "", url: "" }, { name: "", url: "" }],
        featureAvailability: ""
    };
    const termProps = { updateTermInStaging, removeFromStaging, restoreTermToStaging, publishToProd, removeFromProd, createNewTerm, setCreateMode, menuCategories, uploadImage };
    const toolbarProps = { categoriesInUse, filter, setFilter, createMode, setCreateMode, hidePromoted, setHidePromoted, createNewTerm, refreshTerms, loadingProd, loadingArchive, loadingStaging };

    return (<>
        <div className="glossary-container">
            <GlossaryToolbar {...toolbarProps} />

            {createMode && filter.tabView === 'lower' ?
                <GlossaryTermEdit createMode={createMode} term={blankTerm} {...termProps} />
                : <></>}

            <div className='filter-result'>
                {filter.tabView === 'lower' &&
                    (hidePromoted ?
                        <Button size="small" variant="outlined" color="default" startIcon={<ProdIcon />} onClick={() => setHidePromoted(false)} >
                            Un-hide Promoted
                        </Button>
                        :
                        <Button size="small" variant="outlined" color="default" startIcon={<NoProdIcon />} onClick={() => setHidePromoted(true)} >
                            Hide Promoted
                        </Button>
                    )
                }
                {getResultsMessage()}
            </div>
            {filteredTerms && filteredTerms.map(term =>
                <GlossaryTerm key={`${term.status}/${term.id}`} term={term} editState={false} loading={loadingProd || loadingStaging} {...termProps} />)}
        </div>
    </>);
}

export default ManageGlossaryComponent;